var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "VBtn",
            {
              attrs: { color: "primary", flat: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.drawer = true
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.$tr("searchText")) + "\n  ")]
          )
        : _vm._e(),
      _c("CatalogFilterBar"),
      _c(
        "VNavigationDrawer",
        {
          attrs: {
            permanent: _vm.$vuetify.breakpoint.smAndUp,
            app: "",
            "disable-route-watcher": "",
            clipped: _vm.$vuetify.breakpoint.smAndUp,
            right: _vm.isRTL,
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "VContainer",
            { staticClass: "filters pa-3" },
            [
              _vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "VToolbar",
                    { attrs: { color: "transparent", flat: "", dense: "" } },
                    [
                      _c("VSpacer"),
                      _c(
                        "VBtn",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { icon: "", flat: "" },
                        },
                        [
                          _c(
                            "Icon",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.drawer = false
                                },
                              },
                            },
                            [_vm._v("\n            clear\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("VTextField", {
                attrs: {
                  color: "primary",
                  label: _vm.$tr("searchLabel"),
                  box: "",
                  clearable: "",
                  "data-test": "keywords",
                  autofocus: "",
                },
                on: { input: _vm.setKeywords },
                model: {
                  value: _vm.keywordInput,
                  callback: function ($$v) {
                    _vm.keywordInput = $$v
                  },
                  expression: "keywordInput",
                },
              }),
              _c("LanguageFilter", {
                attrs: { "menu-props": _vm.menuProps },
                model: {
                  value: _vm.languages,
                  callback: function ($$v) {
                    _vm.languages = $$v
                  },
                  expression: "languages",
                },
              }),
              !_vm.libraryMode
                ? _c("MultiSelect", {
                    attrs: {
                      items: _vm.licenseOptions,
                      label: _vm.$tr("licenseLabel"),
                      "item-text": "text",
                    },
                    model: {
                      value: _vm.licenses,
                      callback: function ($$v) {
                        _vm.licenses = $$v
                      },
                      expression: "licenses",
                    },
                  })
                : _vm._e(),
              _c("MultiSelect", {
                attrs: {
                  items: _vm.kindOptions,
                  label: _vm.$tr("formatLabel"),
                  "item-text": "text",
                },
                model: {
                  value: _vm.kinds,
                  callback: function ($$v) {
                    _vm.kinds = $$v
                  },
                  expression: "kinds",
                },
              }),
              _vm.loggedIn
                ? _c("Checkbox", {
                    attrs: { color: "primary", label: _vm.$tr("starredLabel") },
                    model: {
                      value: _vm.bookmark,
                      callback: function ($$v) {
                        _vm.bookmark = $$v
                      },
                      expression: "bookmark",
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "subheading" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tr("includesLabel")) + "\n      "
                ),
              ]),
              _c("Checkbox", {
                attrs: { color: "primary" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.$tr("coachLabel"))),
                        ]),
                        _c("HelpTooltip", {
                          staticClass: "px-2",
                          attrs: {
                            text: _vm.$tr("coachDescription"),
                            bottom: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.coach,
                  callback: function ($$v) {
                    _vm.coach = $$v
                  },
                  expression: "coach",
                },
              }),
              _c("Checkbox", {
                attrs: { color: "primary", label: _vm.$tr("subtitlesLabel") },
                model: {
                  value: _vm.subtitles,
                  callback: function ($$v) {
                    _vm.subtitles = $$v
                  },
                  expression: "subtitles",
                },
              }),
              _c("ActionLink", {
                staticClass: "mt-4",
                attrs: {
                  to: _vm.faqLink,
                  target: "_blank",
                  text: _vm.$tr("frequentlyAskedQuestionsLink"),
                },
              }),
            ],
            1
          ),
          _c(
            "VFooter",
            {
              staticClass: "pb-3 pt-2 px-4",
              attrs: { color: "transparent", height: "100" },
            },
            [
              _c(
                "div",
                [
                  _c("VImg", {
                    staticClass: "mb-1 mr-2",
                    attrs: {
                      height: "60",
                      width: "90",
                      contain: "",
                      src: require("shared/images/le-logo.svg"),
                    },
                  }),
                  _c("ActionLink", {
                    attrs: {
                      text: _vm.$tr("copyright", {
                        year: new Date().getFullYear(),
                      }),
                      href: "https://learningequality.org/",
                      target: "_blank",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }