var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "figure",
    {
      staticClass: "thumbnail",
      class:
        ((_obj = {}),
        (_obj[_vm.kind] = _vm.compact),
        (_obj["icon-only"] = _vm.compact),
        (_obj["nothumbnail"] = !_vm.showThumbnail && !_vm.compact),
        _obj),
      style: { "max-width": _vm.maxWidth },
    },
    [
      _vm.kind && !_vm.printing && _vm.showKind && !_vm.compact
        ? _c(
            "VLayout",
            {
              staticClass: "caption",
              class: _vm.kind,
              attrs: { tag: "figcaption", row: "", "align-center": "" },
            },
            [
              _c(
                "VFlex",
                { staticClass: "px-1", attrs: { shrink: "" } },
                [
                  !_vm.compact
                    ? _c("Icon", {
                        attrs: {
                          dark: "",
                          small: "",
                          "aria-label": _vm.kindTitle,
                        },
                        domProps: { textContent: _vm._s(_vm.icon) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("VFlex", { attrs: { shrink: "" } }, [
                _c("span", { staticClass: "caption white--text" }, [
                  _vm._v(_vm._s(_vm.kindTitle)),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showThumbnail
        ? _c("img", {
            staticClass: "thumbnail-image",
            style: { objectFit: _vm.objectFit },
            attrs: {
              src: _vm.thumbnailSrc,
              alt: _vm.$tr("thumbnail", { title: _vm.title }),
            },
          })
        : _vm.printing
        ? _c(
            "div",
            { staticClass: "printable-icon" },
            [
              _c(
                "Icon",
                {
                  attrs: {
                    color: _vm.$vuetify.theme[_vm.kind],
                    "capture-as-image": "",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")]
              ),
            ],
            1
          )
        : _vm.compact
        ? _c(
            "svg",
            {
              staticClass: "thumbnail-image",
              attrs: { viewBox: "0 0 24 24", "aria-label": _vm.kindTitle },
            },
            [
              _c(
                "text",
                {
                  staticClass: "material-icons notranslate v-icon",
                  attrs: { x: -1, y: _vm.y, fill: "#ffffff" },
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
            ]
          )
        : _c(
            "svg",
            {
              staticClass: "nothumbnail-image",
              class: _vm.$isRTL ? "rtl-image" : "ltr-image",
              attrs: { viewBox: "0 0 24 24", "aria-label": _vm.kindTitle },
            },
            [
              _c(
                "text",
                {
                  staticClass: "material-icons notranslate v-icon",
                  attrs: {
                    x: -1,
                    y: _vm.y - 3,
                    fill: _vm.$vuetify.theme.greyBorder,
                  },
                },
                [_vm._v("image")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }