var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "IconButton",
        _vm._b(
          {
            attrs: {
              "data-test": "button",
              icon: _vm.bookmark ? "star" : "starBorder",
              text: _vm.starText,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.toggleStar.apply(null, arguments)
              },
            },
          },
          "IconButton",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }