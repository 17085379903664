var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t(
        "default",
        function () {
          return [
            _vm.allowDrop
              ? _c("FileDropzone", {
                  attrs: { disabled: _vm.readonly },
                  on: { dropped: _vm.handleFiles, click: _vm.openFileDialog },
                })
              : _vm._e(),
          ]
        },
        { openFileDialog: _vm.openFileDialog, handleFiles: _vm.handleFiles }
      ),
      !_vm.readonly
        ? _c("input", {
            ref: "fileUpload",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              accept: _vm.acceptedMimetypes,
              multiple: _vm.allowMultiple,
              "data-test": "upload-dialog",
            },
            on: {
              change: function ($event) {
                return _vm.handleFiles($event.target.files)
              },
            },
          })
        : _vm._e(),
      _c("Alert", {
        attrs: {
          header: _vm.$tr("unsupportedFilesHeader"),
          text: _vm.unsupportedFilesText,
        },
        model: {
          value: _vm.showUnsupportedFilesAlert,
          callback: function ($$v) {
            _vm.showUnsupportedFilesAlert = $$v
          },
          expression: "showUnsupportedFilesAlert",
        },
      }),
      _c("Alert", {
        attrs: {
          header: _vm.$tr("tooLargeFilesHeader"),
          text: _vm.$tr("maxFileSizeText", {
            count: _vm.tooLargeFiles.length,
            size: _vm.formatFileSize(_vm.maxFileSize),
          }),
        },
        model: {
          value: _vm.showTooLargeFilesAlert,
          callback: function ($$v) {
            _vm.showTooLargeFilesAlert = $$v
          },
          expression: "showTooLargeFilesAlert",
        },
      }),
      _c("Alert", {
        attrs: { header: _vm.$tr("noStorageHeader"), text: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "storage-alert" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$tr("uploadSize", {
                          size: _vm.formatFileSize(_vm.totalUploadSize),
                        })
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$tr("remainingStorage", {
                            size: _vm.formatFileSize(_vm.availableSpace),
                          })
                        ) +
                        "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "storage-usage" },
                    [_c("FileStorage")],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showStorageExceededAlert,
          callback: function ($$v) {
            _vm.showStorageExceededAlert = $$v
          },
          expression: "showStorageExceededAlert",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }