var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CatalogFilters"),
      _c(
        "VSlideYTransition",
        [
          _c(
            "ToolBar",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.offline,
                  expression: "offline",
                },
              ],
              attrs: { dense: "", "clipped-left": "", absolute: "" },
            },
            [_c("OfflineText")],
            1
          ),
        ],
        1
      ),
      _c(
        "VContainer",
        {
          class: _vm.$vuetify.breakpoint.xsOnly ? "pa-0" : "pa-4",
          style: "margin-top: " + (_vm.offline ? 48 : 0),
          attrs: { fluid: "" },
        },
        [
          _vm.loading
            ? _c("LoadingText")
            : _c(
                "VLayout",
                {
                  staticClass: "list-wrapper py-4",
                  attrs: { grid: "", wrap: "" },
                },
                [
                  _c(
                    "VFlex",
                    { staticClass: "mb-2", attrs: { xs12: "" } },
                    [
                      _c("h1", { staticClass: "mb-2 ml-1 title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$tr("resultsText", { count: _vm.page.count })
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm.page.count && !_vm.selecting
                        ? _c("ActionLink", {
                            attrs: {
                              text: _vm.$tr("selectChannels"),
                              "data-test": "select",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setSelection(true)
                              },
                            },
                          })
                        : _vm.selecting
                        ? _c("Checkbox", {
                            staticClass: "mb-4 mx-2",
                            attrs: {
                              label: _vm.$tr("selectAll"),
                              "data-test": "select-all",
                              indeterminate:
                                0 < _vm.selected.length &&
                                _vm.selected.length < _vm.channels.length,
                            },
                            model: {
                              value: _vm.selectAll,
                              callback: function ($$v) {
                                _vm.selectAll = $$v
                              },
                              expression: "selectAll",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "VFlex",
                    { attrs: { xs12: "" } },
                    _vm._l(_vm.channels, function (item) {
                      return _c(
                        "VLayout",
                        { key: item.id, attrs: { "align-center": "" } },
                        [
                          _c("Checkbox", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selecting,
                                expression: "selecting",
                              },
                            ],
                            staticClass: "mx-2",
                            attrs: { value: item.id, "data-test": "checkbox" },
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          }),
                          _c("ChannelItem", {
                            staticStyle: { "flex-grow": "1", width: "100%" },
                            attrs: {
                              channelId: item.id,
                              detailsRouteName: _vm.detailsRouteName,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "VFlex",
                    {
                      staticStyle: { "padding-bottom": "72px" },
                      attrs: { xs12: "" },
                    },
                    [
                      _c(
                        "VLayout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c("Pagination", {
                            attrs: {
                              pageNumber: _vm.page.page_number,
                              totalPages: _vm.page.total_pages,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm.selecting
            ? _c(
                "BottomToolBar",
                {
                  attrs: {
                    "clipped-left": "",
                    color: "white",
                    flat: "",
                    "data-test": "toolbar",
                    height: _vm.$vuetify.breakpoint.xsOnly ? "72px" : "56px",
                  },
                },
                [
                  _c(
                    "VLayout",
                    { attrs: { row: "", wrap: "", "align-center": "" } },
                    [
                      _c(
                        "VFlex",
                        { staticClass: "pb-1", attrs: { xs12: "", sm4: "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tr("channelSelectionCount", {
                                  count: _vm.selectedCount,
                                })
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "VFlex",
                        { attrs: { xs12: "", sm8: "" } },
                        [
                          _c(
                            "VLayout",
                            { attrs: { row: "" } },
                            [
                              _c("VSpacer"),
                              _c(
                                "VBtn",
                                {
                                  staticClass: "ma-0",
                                  attrs: { flat: "", "data-test": "cancel" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSelection(false)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$tr("cancelButton")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c(
                                "Menu",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "VBtn",
                                              _vm._g(
                                                {
                                                  staticClass: "ma-0 mx-2",
                                                  attrs: { color: "primary" },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$tr("downloadButton")
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _c(
                                                  "Icon",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _vm._v(
                                                      "\n                    arrow_drop_up\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    657945243
                                  ),
                                },
                                [
                                  _c(
                                    "VList",
                                    [
                                      _c(
                                        "VListTile",
                                        { on: { click: _vm.downloadPDF } },
                                        [
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("downloadPDF"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "VListTile",
                                        {
                                          attrs: {
                                            "data-test": "download-csv",
                                          },
                                          on: { click: _vm.downloadCSV },
                                        },
                                        [
                                          _c("VListTileTitle", [
                                            _vm._v(
                                              _vm._s(_vm.$tr("downloadCSV"))
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }