var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VTooltip",
    _vm._b(
      {
        attrs: { maxWidth: "150px", lazy: "" },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _c(
                  "Icon",
                  _vm._g({ attrs: { color: "primary", small: _vm.small } }, on),
                  [_vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")]
                ),
              ]
            },
          },
        ]),
      },
      "VTooltip",
      _vm.$attrs,
      false
    ),
    [_c("span", { staticClass: "text-xs-center" }, [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }