var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { printing: _vm.printing } },
    [
      _c(
        "div",
        { staticStyle: { "max-width": "300px" } },
        [
          _c("Thumbnail", {
            attrs: {
              src: _vm.isChannel
                ? _vm._details.thumbnail_url
                : _vm._details.thumbnail_src,
              encoding: _vm.isChannel ? _vm._details.thumbnail_encoding : null,
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c("h1", { staticClass: "notranslate", attrs: { dir: "auto" } }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.isChannel ? _vm._details.name : _vm._details.title) +
            "\n  "
        ),
      ]),
      _c("p", { staticClass: "notranslate", attrs: { dir: "auto" } }, [
        _vm._v("\n    " + _vm._s(_vm._details.description) + "\n  "),
      ]),
      _c("br"),
      _vm.isChannel
        ? [
            _vm._details.published && _vm._details.primary_token
              ? _c("DetailsRow", {
                  attrs: { label: _vm.$tr("tokenHeading") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            !_vm.printing
                              ? _c("CopyToken", {
                                  staticStyle: { "max-width": "max-content" },
                                  attrs: { token: _vm._details.primary_token },
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm._details.primary_token.slice(0, 5) +
                                          "-" +
                                          _vm._details.primary_token.slice(5)
                                      ) +
                                      "\n        "
                                  ),
                                ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1103360544
                  ),
                })
              : _vm._e(),
            _c(
              "DetailsRow",
              { attrs: { label: _vm.$tr("publishedHeading") } },
              [
                _vm._details.published
                  ? _c("span", [_vm._v(_vm._s(_vm.publishedDate))])
                  : _c("em", [_vm._v(_vm._s(_vm.$tr("unpublishedText")))]),
              ]
            ),
            _c(
              "DetailsRow",
              { attrs: { label: _vm.$tr("currentVersionHeading") } },
              [
                _vm._details.published
                  ? [
                      _vm._v(
                        "\n        " + _vm._s(_vm._details.version) + "\n      "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n        " + _vm._s(_vm.defaultText) + "\n      "
                      ),
                    ],
              ],
              2
            ),
            _vm._details.language
              ? _c("DetailsRow", {
                  attrs: {
                    label: _vm.$tr("primaryLanguageHeading"),
                    text: _vm.translateLanguage(_vm._details.language),
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.loading
        ? _c("LoadingText")
        : _vm.hasDetails
        ? _c(
            "div",
            [
              _c("DetailsRow", {
                attrs: {
                  label: _vm.$tr("creationHeading"),
                  text: _vm.createdDate,
                },
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("sizeHeading"), text: _vm.sizeText },
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("resourceHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$formatNumber(_vm._details.resource_count)
                            )
                          ),
                        ]),
                        _c("VDataTable", {
                          staticClass: "kind-table",
                          attrs: {
                            items: _vm.kindCount,
                            "hide-actions": "",
                            "hide-headers": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "pr-2 py-0",
                                      staticStyle: { width: "24px" },
                                    },
                                    [
                                      _c("ContentNodeIcon", {
                                        attrs: { kind: item.kind_id },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "kind-name pa-0" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.translateConstant(item.kind_id)
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "pa-0 text-xs-right" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$formatNumber(item.count)
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("levelsHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.levels.length
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.defaultText) +
                                  "\n        "
                              ),
                            ])
                          : !_vm.printing
                          ? _vm._l(_vm.levels, function (level) {
                              return _c(
                                "VChip",
                                { key: level, staticClass: "tag" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(level) +
                                      "\n        "
                                  ),
                                ]
                              )
                            })
                          : _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.levelsPrintable) +
                                  "\n        "
                              ),
                            ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("categoriesHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.categories.length
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.defaultText) +
                                  "\n        "
                              ),
                            ])
                          : !_vm.printing
                          ? _vm._l(_vm.categories, function (category) {
                              return _c(
                                "VChip",
                                { key: category, staticClass: "tag" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(category) +
                                      "\n        "
                                  ),
                                ]
                              )
                            })
                          : _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.categoriesPrintable) +
                                  "\n        "
                              ),
                            ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("containsHeading") },
                scopedSlots: _vm._u(
                  [
                    !_vm.printing
                      ? {
                          key: "default",
                          fn: function () {
                            return [
                              _vm._details.includes.coach_content
                                ? _c("VChip", { staticClass: "tag" }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.$tr("coachHeading")) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._details.includes.exercises
                                ? _c("VChip", { staticClass: "tag" }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.$tr("assessmentsIncludedText")
                                        ) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm._details.includes.exercises &&
                              !_vm._details.includes.coach_content
                                ? _c("div", [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(_vm.defaultText) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : {
                          key: "default",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.includesPrintable)),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                  ],
                  null,
                  true
                ),
              }),
              _c("DetailsRow", {
                attrs: {
                  label: _vm.$tr("coachHeading"),
                  text: _vm.$formatNumber(_vm._details.includes.coach_content),
                  definition: !_vm.printing ? _vm.$tr("coachDescription") : "",
                },
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("tagsHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.sortedTags.length
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.defaultText) +
                                  "\n        "
                              ),
                            ])
                          : !_vm.printing
                          ? _vm._l(_vm.sortedTags, function (tag) {
                              return _c(
                                "VChip",
                                { key: tag.tag_name, staticClass: "tag" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(tag.tag_name) +
                                      "\n        "
                                  ),
                                ]
                              )
                            })
                          : _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.tagPrintable) +
                                  "\n        "
                              ),
                            ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("languagesHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            noItemsText: _vm.defaultText,
                            items: _vm._details.languages,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("subtitlesHeading") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            noItemsText: _vm.defaultText,
                            items: _vm._details.accessible_languages,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: {
                  label: _vm.$tr("authorsLabel"),
                  definition: !_vm.printing ? _vm.$tr("authorToolTip") : "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            noItemsText: _vm.defaultText,
                            items: _vm._details.authors,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: {
                  label: _vm.$tr("providersLabel"),
                  definition: !_vm.printing ? _vm.$tr("providerToolTip") : "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            noItemsText: _vm.defaultText,
                            items: _vm._details.providers,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: {
                  label: _vm.$tr("aggregatorsLabel"),
                  definition: !_vm.printing ? _vm.$tr("aggregatorToolTip") : "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            noItemsText: _vm.defaultText,
                            items: _vm._details.aggregators,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("licensesLabel") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !_vm.printing
                          ? _vm._l(_vm._details.licenses, function (license) {
                              return _c(
                                "VTooltip",
                                {
                                  key: license,
                                  attrs: { top: "", lazy: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "VChip",
                                              _vm._g(
                                                { staticClass: "tag" },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.translateConstant(
                                                        license
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translateConstant(
                                          license + "_description"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            })
                          : _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.licensesPrintable) +
                                  "\n        "
                              ),
                            ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("DetailsRow", {
                attrs: { label: _vm.$tr("copyrightHoldersLabel") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("ExpandableList", {
                          attrs: {
                            items: _vm._details.copyright_holders,
                            "no-items-text": _vm.defaultText,
                            printing: _vm.printing,
                            inline: "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._details.original_channels.length
                ? _c("DetailsRow", {
                    attrs: { label: _vm.$tr("containsContentHeading") },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return _vm._l(
                              _vm._details.original_channels,
                              function (channel) {
                                return _c(
                                  "VLayout",
                                  {
                                    key: channel.id,
                                    staticClass: "preview-row",
                                    attrs: { "align-center": "", row: "" },
                                  },
                                  [
                                    _c(
                                      "VFlex",
                                      { staticClass: "source-thumbnail" },
                                      [
                                        _c("Thumbnail", {
                                          attrs: { src: channel.thumbnail },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.libraryMode
                                      ? _c(
                                          "VFlex",
                                          {
                                            staticClass:
                                              "font-weight-bold notranslate px-4 subheading",
                                          },
                                          [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(channel.name) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "notranslate primary--text",
                                            attrs: {
                                              href: _vm.channelUrl(channel),
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(channel.name)),
                                            ]),
                                            _c(
                                              "Icon",
                                              {
                                                staticClass: "mx-1 rtl-flip",
                                                attrs: {
                                                  small: "",
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v("open_in_new")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }
                            )
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2744112493
                    ),
                  })
                : _vm._e(),
              _vm._details.sample_nodes.length
                ? _c(
                    "label",
                    {
                      staticClass: "body-1 font-weight-bold",
                      style: { color: _vm.$vuetify.theme.darkGrey },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.isChannel
                              ? _vm.$tr("sampleFromChannelHeading")
                              : _vm.$tr("sampleFromTopicHeading")
                          ) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "VLayout",
                {
                  staticClass: "my-4 pt-1 sample-nodes",
                  attrs: { row: "", wrap: !_vm.printing },
                },
                _vm._l(_vm._details.sample_nodes, function (node) {
                  return _c(
                    "VFlex",
                    {
                      key: node.node_id,
                      attrs: {
                        xs12: !_vm.printing,
                        xs3: _vm.printing,
                        sm3: "",
                      },
                    },
                    [
                      _c(
                        "VCard",
                        { attrs: { height: "100%", flat: "" } },
                        [
                          _c("Thumbnail", {
                            attrs: { src: node.thumbnail, kind: node.kind },
                          }),
                          _c("VCardText", { class: _vm.getTitleClass(node) }, [
                            _c("p", { attrs: { dir: "auto" } }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getTitle(node)) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }