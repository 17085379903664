var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VAutocomplete",
    _vm._b(
      {
        attrs: {
          items: _vm.availableLanguages,
          label: _vm.$tr("languageLabel"),
          color: "primary",
          "item-value": "id",
          "item-text": _vm.languageSearchValue,
          autoSelectFirst: "",
          "no-data-text": _vm.$tr("noMatchingLanguageText"),
          box: "",
          multiple: "",
          clearable: "",
          "search-input": _vm.languageInput,
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.languageInput = $event
          },
          "update:search-input": function ($event) {
            _vm.languageInput = $event
          },
          change: function ($event) {
            _vm.languageInput = ""
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "VTooltip",
                  {
                    attrs: { bottom: "", lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c("VChip", _vm._g({ staticClass: "ma-1" }, on), [
                                _c("div", { staticClass: "text-truncate" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.name) +
                                      "\n          "
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v(_vm._s(item.name))])]
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("Checkbox", {
                  key: item.id,
                  staticClass: "mt-0",
                  attrs: { "input-value": _vm.value, value: item.id },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "VTooltip",
                              {
                                attrs: { bottom: "", lazy: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass: "text-truncate",
                                                staticStyle: { width: "250px" },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(item.name) +
                                                  "\n            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.languages,
          callback: function ($$v) {
            _vm.languages = $$v
          },
          expression: "languages",
        },
      },
      "VAutocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }