var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VToolbar",
    _vm._b(
      { staticStyle: { top: "unset", bottom: "0em" }, attrs: { fixed: "" } },
      "VToolbar",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }