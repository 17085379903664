var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (!_vm.storageIsFull && !_vm.showWarning) || _vm.showProgress
        ? _c("span", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$tr("storageUsed", {
                    used: _vm.formatFileSize(_vm.usedSpace),
                    total: _vm.formatFileSize(_vm.totalSpace),
                  })
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm.showProgress
        ? _c("VProgressLinear", {
            attrs: { color: _vm.progressBarColor, value: _vm.storagePercent },
          })
        : _vm._e(),
      _vm.storageIsFull
        ? _c("span", { staticClass: "red--text" }, [
            _vm.showProgress
              ? _c("span", [_vm._v(_vm._s(_vm.$tr("storageFull")))])
              : _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$tr("storageFullWithSize", {
                          used: _vm.formatFileSize(_vm.usedSpace),
                          total: _vm.formatFileSize(_vm.totalSpace),
                        })
                      ) +
                      "\n    "
                  ),
                ]),
          ])
        : _vm.showWarning
        ? _c(
            "span",
            [
              _c("Icon", { attrs: { color: "amber", small: "" } }, [
                _vm._v("warning"),
              ]),
              _vm.showProgress
                ? _c("span", [_vm._v(_vm._s(_vm.$tr("storageLow")))])
                : _c("span", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$tr("storageLowWithSize", {
                            used: _vm.formatFileSize(_vm.usedSpace),
                            total: _vm.formatFileSize(_vm.totalSpace),
                          })
                        ) +
                        "\n    "
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
      _vm.storageIsFull || _vm.showWarning
        ? _c("ActionLink", {
            staticClass: "ml-3",
            attrs: {
              target: "_blank",
              text: _vm.$tr("requestStorage"),
              href: _vm.storageRequestUrl,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }